import React, { useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const PhoneNumberForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [darkMode, setDarkMode] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneNumberParsed = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberParsed && phoneNumberParsed.isValid()) {
      const whatsappURL = `https://wa.me/${phoneNumberParsed.number}${message ? `?text=${encodeURIComponent(message)}` : ''}`;
      window.open(whatsappURL, '_blank');
    } else {
      setError('Por favor, ingresa un número de teléfono válido en formato internacional.');
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.add('dark');
    }
  };

  return (
    <div className={`flex items-center justify-center h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <form onSubmit={handleSubmit} className={`bg-white dark:bg-gray-800 p-6 rounded shadow-md w-full max-w-md`}>
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800 dark:text-white">WhatsApp Chat</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700 dark:text-gray-300 font-bold mb-2">
            Número de Teléfono (Formato Internacional)
          </label>
          <input
            type="tel"
            id="phone"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 dark:text-gray-300 font-bold mb-2">
            Mensaje (Opcional)
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-4"
        >
          Iniciar Chat
        </button>
        <button
          type="button"
          onClick={toggleDarkMode}
          className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
        >
          {darkMode ? 'Modo Claro' : 'Modo Oscuro'}
        </button>
      </form>
    </div>
  );
};

export default PhoneNumberForm;
