import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAxAYeYXoibCozO8BDeZMh6JO7iyfdlB7k",
  authDomain: "notificaciones-3a007.firebaseapp.com",
  projectId: "notificaciones-3a007",
  storageBucket: "notificaciones-3a007.appspot.com",
  messagingSenderId: "689950687788",
  appId: "1:689950687788:web:c9089926cef18a500a4789",
  measurementId: "G-7VKEY0RC8Y"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

// Obtener el token para las notificaciones push
getToken(messaging, { vapidKey: 'AIzaSyAxAYeYXoibCozO8BDeZMh6JO7iyfdlB7k' }).then((currentToken) => {
  if (currentToken) {
    console.log('Token received: ', currentToken);
    // Enviar el token al servidor
  } else {
    console.log('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
});

// Manejar mensajes recibidos
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // Personalizar la notificación aquí
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.icon
  };

  if (Notification.permission === 'granted') {
    new Notification(notificationTitle, notificationOptions);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Registrar el service worker para PWA
serviceWorkerRegistration.register();
